<template>
  <div>
    <b-card>
      <b-row>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-input-group>
              <b-form-datepicker
                id="sdate"
                v-model="filterData.sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="clearSdate"
                >
                  <FeatherIcon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-input-group>
              <b-form-datepicker
                id="edate"
                v-model="filterData.edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="clearEdate"
                >
                  <FeatherIcon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          cols="12"
        >
          <b-form-group
            label="Marka"
            label-for="id_com_brand"
          >
            <v-select
              id="id_com_brand"
              v-model="filterData.id_com_brand"
              :options="brands"
              :reduce="item => item.id"
              label="title"
              placeholder="Seçiniz"
              multiple
              :close-on-select="false"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Departman"
            label-for="id_com_department"
          >
            <v-select
              id="id_com_department"
              v-model="filterData.id_com_department"
              placeholder="Departman"
              :options="departments"
              label="title"
              :close-on-select="false"
              :reduce="department => department.id"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            :disabled="loading"
            @click="getReport"
          >
            <FeatherIcon icon="PieChartIcon" />
            Raporu Görüntüle
          </b-button>
          <b-button
            variant="primary"
            class="ml-2"
            :href="downloadUrl"
            target="_blank"
            :disabled="!report.count"
          >
            <FeatherIcon icon="DownloadIcon" />
            Excel Döküm Al
          </b-button>
          <b-button
            variant="primary"
            class="ml-2"
            :href="downloadUrlPDF"
            target="_blank"
            :disabled="!report.count"
          >
            <FeatherIcon icon="DownloadIcon" />
            PDF Döküm Al
          </b-button>
        </b-col>
      </b-row>

    </b-card>
    <pre-loading v-if="loading" />
    <template v-if="report.count">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Genel Durum</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row v-if="report.data.general.length > 0">
            <b-col
              v-for="(detail,key) in report.data.general"
              :key="key"
            >
              <div class="border p-2 text-center rounded bg-light mb-2">
                <div class="font-medium-3 font-weight-light text-nowrap">
                  {{ detail.title }}
                </div>
                <div class="font-weight-bolder font-large-1 text-primary">
                  {{ detail.count | toNumber }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-alert
            v-else
            show
          >
            <div class="alert-body text-center">
              Servis başvuru verisi bulunmamaktadır.
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
    </template>
    <template v-if="report.data.brands">
      <div
        v-for="(brand,key) in report.data.brands"
        :key="key"
      >
        <b-card
          v-if="brand.totals.totalCrm > 0"
          no-body
        >
          <b-card-header header-bg-variant="primary">
            <b-card-title class="text-white">
              {{ brand.title }}
            </b-card-title>
          </b-card-header>
          <b-card-body class="mt-3">
            <b-row>
              <b-col>
                <div class="border p-2 text-center rounded bg-light mb-2">
                  <div class="font-medium-3 font-weight-light text-nowrap">
                    Toplam Başvuru
                  </div>
                  <div class="font-weight-bolder font-large-1 text-primary">
                    {{ brand.totals.totalCrm | toNumber }}
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="border p-2 text-center rounded bg-light mb-2">
                  <div class="font-medium-3 font-weight-light text-nowrap">
                    Ulaşılan Müşteri
                  </div>
                  <div class="font-weight-bolder font-large-1 text-primary">
                    {{ brand.totals.reached | toNumber }}
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="border p-2 text-center rounded bg-light mb-2">
                  <div class="font-medium-3 font-weight-light text-nowrap">
                    Ulaşılamayan Müşteri
                  </div>
                  <div class="font-weight-bolder font-large-1 text-primary">
                    {{ brand.totals.unReached | toNumber }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <b-table-simple
            fixed
            class="text-center"
          >
            <b-tbody>
              <b-tr>
                <b-th>
                  Departman
                </b-th>
                <b-th>
                  Toplam Başvuru
                </b-th>
                <b-th>
                  Ulaşılan Müşteri
                </b-th>
                <b-th>
                  Ulaşılamayan Müşteri
                </b-th>
              </b-tr>
              <b-tr
                v-for="(department,index) in brand.departments"
                :key="index"
              >
                <b-td>
                  {{ department.title }}
                </b-td>
                <b-td>
                  {{ department.totalCrm }}
                </b-td>
                <b-td>
                  {{ department.reacheds }}
                </b-td>
                <b-td>
                  {{ department.unReached }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BFormDatepicker,
  BButton,
  BAlert,
  BInputGroup,
  BInputGroupAppend,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'UnreachableRequests',
  components: {
    vSelect,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BAlert,
    BInputGroup,
    BInputGroupAppend,
    PreLoading,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  computed: {
    loading() {
      return this.$store.getters['UnreachableRequests/getLoading']
    },
    downloadUrl() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_brand) {
        this.filterData.id_com_brand.forEach(e => {
          urlParams.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.filterData.id_com_department) {
        this.filterData.id_com_department.forEach(e => {
          urlParams.push(`id_com_department[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/crm/Unreachable_Requests/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    downloadUrlPDF() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_brand) {
        this.filterData.id_com_brand.forEach(e => {
          urlParams.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.filterData.id_com_department) {
        this.filterData.id_com_department.forEach(e => {
          urlParams.push(`id_com_department[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/crm/Unreachable_Requests/pdf?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    report() {
      return this.$store.getters['UnreachableRequests/getReport']
    },
    filterData() {
      return this.$store.getters['UnreachableRequests/getFilterData']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
  },
  created() {
    this.$store.commit('UnreachableRequests/SET_RESET_FILTER_DATA')
    this.getReport()
    this.getBrands()
    this.getDepartments()
  },
  methods: {
    getReport() {
      this.$store.dispatch('UnreachableRequests/report', this.filterData)
    },
    clearSdate() {
      this.filterData.sdate = null
    },
    clearEdate() {
      this.filterData.edate = null
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.serviceStatus': 1,
        },
        or_where: {
          'com_brand.id': 6,
        },
      })
    },
    getDepartments() {
      this.$store.dispatch('departments/departmentsList', {
        select: ['com_department.id AS id', 'com_department.title AS title'],
        where: {
          web: 1,
        },
      })
    },
  },

}
</script>
